import { PAGES } from '@pp/constants/config'
import { useCategoriesData } from '@pp/hooks/useCategoriesData'
import { Flex, Text } from '@prontopro/ui-toolkit'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { Wrapper } from './Wrapper'

const Width100Percent = styled(Flex)`
  width: 100%;
`

export const Categories = () => {
  const data = useCategoriesData()

  return (
    <Wrapper align="center">
      <Width100Percent py={16} direction="row">
        <Flex pr={16}>
          <GatsbyLink to="/blog/">
            <Text kind="bodyMMedium" textTransform="capitalize">
              all
            </Text>
          </GatsbyLink>
        </Flex>

        {data.tags.distinct.map(tag => (
          <Flex key={tag} pr={16}>
            <GatsbyLink to={`/${PAGES.TAGS}/${tag}`}>
              <Text kind="bodyMMedium" textTransform="capitalize">
                {tag}
              </Text>
            </GatsbyLink>
          </Flex>
        ))}
      </Width100Percent>
    </Wrapper>
  )
}
