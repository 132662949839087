import { PAGES } from '@pp/constants/config'
import { Flex, Text } from '@prontopro/ui-toolkit'
import { Link } from 'gatsby'
import React from 'react'
import { Info } from './Info'
import { Wrapper } from './Wrapper'

type Props = {
  data: {
    frontmatter: {
      author: {
        firstName: string
        lastName: string
      }
      date: string
      slug: string
      title: string
      spoiler: string
    }
    id: string
    wordCount: {
      words: number
    }
  }
  last: boolean
}

export const PostExcerpt = ({ data, last }: Props) => (
  <Link to={`/${PAGES.BLOG}/${data.frontmatter.slug}`}>
    <Wrapper last={last}>
      <Flex pt={24} pb={8}>
        <Text kind="headingXS" colorName="darkGreen600">
          {data.frontmatter.title}
        </Text>
      </Flex>

      <Info author={data.frontmatter.author} date={data.frontmatter.date} wordCount={data.wordCount.words} />

      <Flex pb={32}>
        {/* eslint-disable-next-line react/no-danger */}
        <Text colorName="darkGreen600" dangerouslySetInnerHTML={{ __html: data.frontmatter.spoiler }} />
      </Flex>
    </Wrapper>
  </Link>
)
