import { colors, Flex } from '@prontopro/ui-toolkit'
import styled from 'styled-components'

export const Wrapper = styled(Flex)`
  border-bottom: 1px solid ${colors.gray100};
  border-top: 1px solid ${colors.gray100};
  overflow-x: scroll;
  position: sticky;
  top: 0;
  background: ${colors.white};
`
