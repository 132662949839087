import { AllMarkdownRemarkDistinctDataFragments } from '@pp/types/data/allMarkdownRemark'
import { graphql, useStaticQuery } from 'gatsby'

export const useCategoriesData = () => {
  const data: AllMarkdownRemarkDistinctDataFragments<{
    tags: string
  }> = useStaticQuery(
    graphql`
      query {
        tags: allMarkdownRemark(filter: { fields: { type: { eq: "blog" } } }) {
          distinct(field: frontmatter___tags)
        }
      }
    `,
  )

  return data
}
