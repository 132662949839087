import styled from 'styled-components'
import { Flex, colors } from '@prontopro/ui-toolkit'

type Props = {
  last: boolean
}

export const Wrapper = styled(Flex)<Props>`
  border-bottom: 1px solid ${({ last }) => (last ? 'transparent' : colors.gray100)};
`
