export const getReadingTime = (wordCount: number): number => {
  const minutes = Math.floor(wordCount / 200)

  if (!minutes) {
    return 1
  }

  return minutes
}

export const capitalize = (text: string) => text
  .match(/(?:(([a-z]+)(?:(-)([a-z]+))*))/)!
  .slice(2)
  .filter(Boolean)
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join('')
