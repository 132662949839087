import { graphql, useStaticQuery } from 'gatsby'
import { AllMarkdownRemarkNodesDataFragments } from '@pp/types/data/allMarkdownRemark'

export const useBlogData = () => {
  const data: AllMarkdownRemarkNodesDataFragments<{
    posts: {
      frontmatter: {
        author: {
          firstName: string
          lastName: string
        }
        date: string
        slug: string
        spoiler: string
        title: string
      }
      id: string
      wordCount: {
        words: number
      }
    }
  }> = useStaticQuery(
    graphql`
      query {
        posts: allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { fields: { type: { eq: "blog" } } }
        ) {
          nodes {
            frontmatter {
              author {
                firstName
                lastName
              }
              date(formatString: "MMMM DD, YYYY")
              slug
              spoiler
              title
            }
            id
            wordCount {
              words
            }
          }
        }
      }
    `,
  )

  return data
}
