export const META = {
  APP_IDS: {
    ITUNES: '1274970171',
    PLAY_STORE: 'com.prontopro.customer',
  },
  COLORS: {
    THEME: '#ffffff',
    TILE: '#da532c',
  },
}

export const PAGES = {
  BLOG: 'blog',
  TAGS: 'tags',
}
