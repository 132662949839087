import { Dot } from '@pp/components/Dot'
import { getReadingTime } from '@pp/utils/text'
import { Text } from '@prontopro/ui-toolkit'
import React from 'react'

type Props = {
  author: {
    firstName: string
    lastName: string
  }
  date: string
  wordCount: number
}

export const Info = ({ author, date, wordCount }: Props) => {
  const readTime = getReadingTime(wordCount)

  return (
    <Text kind="bodyS" colorName="gray300">
      Posted on
      {' '}
      <i>{date}</i>
      {' '}
      by
      {' '}
      <i>
        {author.firstName}
        {' '}
        {author.lastName}
      </i>
      {' '}
      <Dot />
      {readTime}
      min read
    </Text>
  )
}
