import { Text } from '@prontopro/ui-toolkit'
import React from 'react'
import styled from 'styled-components'

const Underlined = styled(Text).attrs(props => ({
  color: props.color,
}))`
  box-shadow: inset 0 -17px 0 0 ${props => props.color};
`

const Inline = styled.div`
  display: inline;
`

type Props = {
  children: string
  color: string
}

export const UnderlinedText = ({ children, color }: Props) => (
  <Inline>
    <Underlined kind="headingL" color={color}>
      {children}
    </Underlined>
  </Inline>
)
