import { Flex } from '@prontopro/ui-toolkit'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const Hero = ({ children }: Props) => (
  <Flex align="flex-start" justify="center" px={24} pt={64} pb={16}>
    {children}
  </Flex>
)
