import { Categories } from '@pp/components/Categories'
import { Content } from '@pp/components/Content'
import { Hero } from '@pp/components/Hero'
import { Layout } from '@pp/components/Layout'
import { MaxWidth1200 } from '@pp/components/MaxWidth'
import { PostExcerpt } from '@pp/components/PostExcerpt'
import { SEO } from '@pp/components/SEO'
import { useBlogData } from '@pp/hooks/useBlogData'
import { UnderlinedText } from '@pp/ui/UnderlinedText'
import { colors, Flex } from '@prontopro/ui-toolkit'
import React from 'react'

const Page = () => {
  const data = useBlogData()

  return (
    <Layout>
      <SEO
        title="ProntoPro Engineering Blog"
        // eslint-disable-next-line max-len
        description="Written by engineers for engineers, this blog is a collection of stories from the people who build and scale ProntoPro, the soon-to-be #1 local-services marketplace in Europe."
      />

      <MaxWidth1200>
        <Hero>
          <UnderlinedText color={colors.lightBlue300}>Engineering Blog</UnderlinedText>
        </Hero>

        <Content>
          <Flex pt={40}>
            <Categories />

            <Flex pt={12} pb={56}>
              {data.posts.nodes.map(node => (
                <PostExcerpt key={node.id} data={node} />
              ))}
            </Flex>
          </Flex>
        </Content>
      </MaxWidth1200>
    </Layout>
  )
}

export default Page
